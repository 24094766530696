
// Text colors
$color-text: #1d1d1b;
$color-text-light: #3C3F49;

//Primary colors
$color-primary: #E90039;
$color-primary-hover: #FF124C;

// Category colors
$color-life: $color-primary;
$color-household:#ffa845 ;
$color-health:#009C16;
$color-auto: #08769C;
$color-employee: #803700;

// Link colors
$color-link-dark: #0025B9;
$color-link-dark-hover: lighten($color-primary, 15%);

// Require colors
$color-require: #e90039;

// Form colors
$input-background: #FFF;
$color-placeholder: #828591;

$color-border: #E1E1E1;
$color-border-focus: #bebebe;

$color-background-white: #FFF;

// Lead statuses - colors
$verified:  #08769C;
$new: #009C16;
$hot: #9c0000;
$read: #969696;

// Lead statuses - component colors
$statuses: (
    verified: (
        'background': lighten($verified, 62%),
        'border': lighten($verified, 10%),
        'text': $verified
    ),
    new: (
        'background': lighten($new, 62%),
        'border': lighten($new, 10%),
        'text': $new
    ),
    hot: (
        'background': lighten($hot, 62%),
        'border': lighten($hot, 10%),
        'text': $hot
    ),
    read: (
        'background': lighten($read, 62%),
        'border': lighten($read, 10%),
        'text': $read
    ),
);

// Alert colors
$alerts-custom: (
   danger: (
        'background': #FEEFEF,
        'border': #F48989,
        'color': #DA1414
   ),
    warning: (
        'background': #FFF4EC,
        'border': #FF8F39,
        'color': #B95000
    ),
    success: (
        'background': #EDF9F0,
        'border': #5ACA75,
        'color': #287d3C
    )
);

