//
// Forms
//

// Form group
.form-group {
  label {
    font-size: $form-label-font-size;
    font-weight: $form-label-font-weight;
    color: $dark-75;
  }

  .invalid-feedback,
  .valid-feedback {
    font-size: $form-feedback-font-size;
    font-weight: $form-feedback-font-weight;
  }

  .form-text {
    font-size: $form-text-font-size;
    font-weight: $form-text-font-weight;
  }
}

// Form control
.form-control {
  // Readonly state
  &[readonly] {
    background-color: $input-readonly-bg;
  }

  // Pill style
  &.form-control-pill {
    border-radius: $input-line-height;

    &.form-control-sm {
      border-radius: $input-line-height-sm;
    }

    &.form-control-lg {
      border-radius: $input-line-height-lg;
    }
  }

  @if $form-validation-input-shadow == false {
    &:active,
    &.active,
    &:focus,
    &.focus {
      box-shadow: none !important;
    }
  }

  // Solid style
  &.form-control-solid {
    background-color: $input-background;
    border-color: $color-border;
    @include placeholder($color-placeholder);
    color: $input-solid-color;
    transition: $transition-input;

    &.is-invalid {
      border-color: $color-require;
    }

    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $input-background;
      border-color: $input-solid-bg-focus;
      color: $input-solid-color;
      transition: $transition-input;
    }
    &.disabled {
      background-color: $input-solid-bg;
      border: none;
    }
  }
}

// Form controls for amount
.form-group-amount {
  display: inline-block;
  text-align: left;
}

.form-control-amount {
  height: 60px;
  min-width: 200px;
}

.form-control-amount-button {
  height: 60px;
}

// Form controls for code
.form-control-code,
.form-control-code-company,
.form-control-code-button {
    height: 60px;
}

.form-control-code-button {
    font-weight: 600;
}

// Hide Form Control Icon On Validation
.form-control-iconless {
  background-image: none;

  .form-control {
    background-image: none;
  }
}

// Placeholder colors
.placeholder-dark-50 {
  @include placeholder($dark-50);
}

.placeholder-dark-75 {
  @include placeholder($dark-75);
}

.placeholder-white {
  @include placeholder($white);
}

// Custom inputs
.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
}

.custom-file {
  width: 100%;
}

.custom-file-input:focus ~ .custom-file-label {
  box-shadow: none !important;
}

.custom-file-label {
  text-align: left;

  &:after {
    float: left;
  }
}

// Input Group
.input-group {
  .form-control.is-valid + .input-group-append,
  .form-control.is-invalid + .input-group-append {
    margin-left: 0;
  }

  .input-group-prepend + .form-control.is-valid,
  .input-group-prepend + .form-control.is-invalid {
    margin-left: 1px;
  }

  // Sizing
  i {
    line-height: 0;
    font-size: 1.3rem;

    &:after,
    &:before {
      line-height: 0;
    }
  }

  .svg-icon {
    line-height: 0;
  }

  &.input-group-sm {
    i {
      line-height: 0;
      font-size: 1rem;
    }
  }

  &.input-group-lg {
    i {
      line-height: 0;
      font-size: 1.4rem;
    }
  }
  &.input-group-white {
    align-items: center;
    background-color: white;
    border: 1px solid $color-border;
    @include border-radius($input-border-radius);

    &.active,
    &.focus {
      border-color: $input-solid-bg-focus;
      color: $input-solid-color;
      transition: $transition-input;
    }

    .form-control {
      @include input-reset();

      &.form-control-solid {
        &:active,
        &.active,
        &:focus,
        &.focus {
          border-color: $gray-200;
          color: $input-solid-color;
          transition: $transition-input;
        }
      }
    }

    &.input-group-sm {
      @include border-radius($input-border-radius-sm);
    }

    &.input-group-lg {
      @include border-radius($input-border-radius-lg);
    }

    .input-group-prepend,
    .input-group-append,
    .input-group-text {
      background-color: transparent;
      border: 0;
      padding-top: 0;
      padding-bottom: 0;

      .btn-secondary {
        border: 0 !important;
      }
    }

    .input-group-prepend ~ .form-control {
      padding-left: 0 !important;
    }
  }

  // Solid style
  &.input-group-solid {
    align-items: center;
    background-color: $input-solid-bg;
    @include border-radius($input-border-radius);

    &.active,
    &.focus {
      background-color: $input-solid-bg-focus;
      border-color: $input-solid-bg-focus;
      color: $input-solid-color;
      transition: $transition-input;
    }

    .form-control {
      @include input-reset();

      &.form-control-solid {
        &:active,
        &.active,
        &:focus,
        &.focus {
          background-color: $input-solid-bg-focus;
          border-color: $input-solid-bg-focus;
          color: $input-solid-color;
          transition: $transition-input;
        }
		&.disabled{
			background-color: $input-solid-bg;
		}
      }
    }

    &.input-group-sm {
      @include border-radius($input-border-radius-sm);
    }

    &.input-group-lg {
      @include border-radius($input-border-radius-lg);
    }

    .input-group-prepend,
    .input-group-append,
    .input-group-text {
      background-color: transparent;
      border: 0;
      padding-top: 0;
      padding-bottom: 0;

      .btn-secondary {
        border: 0 !important;
      }
    }

    .input-group-prepend ~ .form-control {
      padding-left: 0 !important;
    }
  }
}

// Validation
.validated {
  .valid-feedback,
  .invalid-feedback {
    display: block;
  }
}

// Input icon
.input-icon {
  position: relative;

  span {
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(
      #{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border}
    );

    i {
      line-height: 0;
      color: $dark-50;

      &:after,
      &:before {
        line-height: 0;
      }
    }

    .svg-icon {
      @include svg-icon-color($dark-50);
      @include svg-icon-size(24px);
    }
  }

  .form-control {
    padding-left: calc(
      #{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border}
    );
  }

  &.input-icon-right {
    span {
      right: 0;
      left: auto;
    }

    .form-control {
      padding-left: $input-btn-padding-x;
      padding-right: calc(
        #{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border}
      );
    }
  }
}

// Textarea reset resize
.resize-none {
  resize: none;
}

.main-input {
  padding: 25px 15px 25px 47px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 500;
}

.login-icon {
  position: relative;
  &:before {
    content: url("../../../media/icons/send.png");
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
    width: 16px;
    height: 16px;
  }
}

.password-icon {
  position: relative;
  &:before {
    content: url("../../../media/icons/key.png");
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
    width: 16px;
    height: 16px;
  }
}

.user-icon {
  position: relative;
  &:before {
    content: url("../../../media/icons/user.png");
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
    width: 16px;
    height: 16px;
  }
}

.phone-icon {
  position: relative;
  &:before {
    content: url("../../../media/icons/phone.png");
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
    width: 16px;
    height: 16px;
  }
}

.forgot-password {
  a {
    color: $color-link-dark;
    font-size: 15px;
    font-weight: 500;
    font-family: "Ubuntu", sans-serif;
    letter-spacing: -0.15px;
    &:hover {
      color: $color-link-dark-hover;
    }
  }
}

.require {
  color: $color-require;
  font-size: 15px;
  font-weight: 500;
}

.action-auth {
  color: $color-text-light;
  font-size: 400;
  font-size: 15px;
  font-family: "Ubuntu", sans-serif;
  letter-spacing: -0.15px;
  padding-right: 5px;
  appearance: none;
  a {
    font-weight: 500;
    text-decoration: none;
    color: $color-link-dark;
    letter-spacing: -0.15px;
    appearance: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: $color-link-dark-hover;
    }
  }
}

.hue {
  filter: hue-rotate(130deg);
}

input[type="checkbox"] {
  transform: scale(1.2);
  cursor: pointer;
}

.link {
  font-weight: 500;
  color: #0025b9;
}

// Mobile mode
@include media-breakpoint-down(sm) {
  .col-form-label {
    width: 100%;
    max-width: 100%;
  }

  .form {
    .form-group.row {
      flex-direction: column;
      margin-bottom: 15px;
    }
  }
}

::placeholder {
  color: #3f4254 !important;
  opacity: 1 !important;
}
  
:-ms-input-placeholder,
::-ms-input-placeholder {
  color: #3f4254 !important;
}

.ng-select-multiple {
  .ng-select-container {
    border: 1px solid #e4e6ef !important;

    .ng-placeholder {
      top: unset !important;
      color: #3f4254 !important;
    }
  }

  .ng-dropdown-panel {
    border: 1px solid #e4e6ef !important;
  }

  &.ng-select-opened .ng-select-container,
  &.ng-select-focused .ng-select-container {
    border: 1px solid #ff1d54 !important;
    box-shadow: none !important;
  }
}

select.form-control {
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOTIuNCIgaGVpZ2h0PSIyOTIuNCI+PHBhdGggZmlsbD0iIzk5OTk5OSIgZD0iTTI4NyA2OS40YTE3LjYgMTcuNiAwIDAgMC0xMy01LjRIMTguNGMtNSAwLTkuMyAxLjgtMTIuOSA1LjRBMTcuNiAxNy42IDAgMCAwIDAgODIuMmMwIDUgMS44IDkuMyA1LjQgMTIuOWwxMjggMTI3LjljMy42IDMuNiA3LjggNS40IDEyLjggNS40czkuMi0xLjggMTIuOC01LjRMMjg3IDk1YzMuNS0zLjUgNS40LTcuOCA1LjQtMTIuOCAwLTUtMS45LTkuMi01LjUtMTIuOHoiLz48L3N2Zz4=");
  background-repeat: no-repeat;
  background-position: right .7em top 50%;
  background-size: .65em auto;

  &:focus {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOTIuNCIgaGVpZ2h0PSIyOTIuNCIgdmlld0JveD0iMCAwIDI5Mi40IDI5Mi40Ij4KICA8ZyB0cmFuc2Zvcm09InJvdGF0ZSgxODAgMTQ2LjIgMTQ2LjIpIj4KICAgIDxwYXRoIGZpbGw9IiM5OTk5OTkiIGQ9Ik0yODcgNjkuNGExNy42IDE3LjYgMCAwIDAtMTMtNS40SDE4LjRjLTUgMC05LjMgMS44LTEyLjkgNS40QTE3LjYgMTcuNiAwIDAgMCAwIDgyLjJjMCA1IDEuOCA5LjMgNS40IDEyLjlsMTI4IDEyNy45YzMuNiAzLjYgNy44IDUuNCAxMi44IDUuNHM5LjItMS44IDEyLjgtNS40TDI4NyA5NWMzLjUtMy41IDUuNC03LjggNS40LTEyLjggMC01LTEuOS05LjItNS41LTEyLjh6Ii8+CiAgPC9nPgo8L3N2Zz4K");
  }
}
