@import './variables.main';
//
// Custom alerts for marketplace
//

.mat-alert {
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 20px;
    border-radius: 3px;
    margin: 0 0 20px 0;
    border-width: 1px;

    .mat-alert-icon {
        display: flex;
        align-items: center;
        padding: 0 15px 0 0;

        i {
            font-size: 2.4rem;
            color: #fff;
        }
    }

    .mat-alert-text {
        display: flex;
        align-items: center;
        flex-grow: 1;
        color: #fff;
    }

    @each $type, $val in $alerts-custom{
        &.mat-alert-#{$type}{
            background: get($val, 'background');
            border-color:get($val, 'border');
            color: get($val, 'color');
            i{
                color: get($val, 'color');
            }
        }
    }
}