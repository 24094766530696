//
// Header Mobile
//

// Desktop Mode
@include media-breakpoint-up(xl) {
	.header-mobile {
		display: none;
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(lg) {
	.header-mobile {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 get($page-padding, tablet-and-mobile);
		height: get($header-config, tablet-and-mobile, default, height);
		min-height: get($header-config, tablet-and-mobile, default, height);
		position: relative;
		z-index: 3;

		// Topbar Shown Mode
		.topbar-mobile-on & {
			box-shadow: none;
		}

		// Burger Icon
		.burger-icon {
			@include burger-icon-theme(rgba($white, 0.7), $white, $white);
		}

		// Fixed and Scroll Header Modes
		.header-mobile-fixed[data-header-scroll="on"] & {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: get($header-config, tablet-and-mobile, fixed, zindex);
			background-color: get($header-config, tablet-and-mobile, fixed, bg-color);
			box-shadow: get($header-config, tablet-and-mobile, fixed, shadow);
			animation: header-minimize-animation .5s ease 1;
		}
	}
}
