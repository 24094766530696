/* You can add global styles to this file, and also import other style files */

body {
    margin: 0;
    padding: 0;
}

#splash-screen {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f2f3f8;
}

.floater-bottom {
    position: fixed;
    background-color: red;
    color: white;
    font-weight: bold;
    font-size: 16px;
    padding: 25px;
    width: 100%;
    bottom: 0px;
    left: 0px;
    text-align: center;
}

.floater-top-right {
    position: absolute; 
    top: 5px; 
    right: 5px; 
    text-align: center;
}

.floater-bottom-right {
    position: absolute; 
    bottom: 5px; 
    right: 5px; 
    text-align: center;
}

#splash-screen img {
    margin-left: calc(100vw - 100%);
    margin-bottom: 30px;
}

#splash-screen.hidden {
    opacity: 0;
    visibility: hidden;
}

.splash-spinner {
    animation: rotate 2s linear infinite;
    margin-left: calc(100vw - 100%);
    width: 50px;
    height: 50px;
}

.splash-spinner .path {
    stroke: #5d78ff;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}


/* You can add global styles to this file, and also import other style files */

// @import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
//@import "~bootstrap/dist/css/bootstrap.css";
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
// Global fonts
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";
// Metronic styles (replace these path when using RTL css below)
@import "./assets/sass/style.angular.scss";
// For RTL, check this documentation https://keenthemes.com/metronic/?page=docs&section=angular-rtl
// @import "./assets/sass/style.angular.rtl.css";s
@import "~@ng-select/ng-select/themes/default.theme.css";
.ng-select.custom.small .ng-select-container {
    height: calc(1.5em + 1.2rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
}

.ng-select.custom .ng-select-container {
    background-color: $input-solid-bg;
    border-color: $input-solid-bg;
    @include placeholder($input-solid-placeholder-color);
    color: $input-solid-color;
    transition: $transition-input;
    height: calc(1.5em + 1.65rem + 2px);
    padding: 0.825rem 1.42rem;
    font-size: 1.08rem;
    line-height: 1.5;
    border-radius: 0.42rem;
    .ng-value-container {
        padding: 0;
        .ng-input {
            top: 50%;
            transform: translateY(-50%);
            padding-left: 1.42rem;
        }
    }
    &:active,
    &.active,
    &:focus,
    &.focus {
        background-color: $input-solid-bg-focus;
        border-color: $input-solid-bg-focus;
        color: $input-solid-color;
        transition: $transition-input;
    }
    .ng-clear-wrapper {
        display: flex;
        height: 100%;
        align-items: center;
        .ng-clear {
            padding-top: 2px;
        }
    }
    .ng-arrow-wrapper {
        .ng-arrow {
            margin-top: 8.25px;
        }
    }
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
    display: none !important;
}

.angular-bootstrap-table {
    overflow-x: auto;
}

.angular-bootstrap-table {
    th {
        white-space: nowrap;
        outline: none;
        &.sortable {
            .svg-icon-sort {
                opacity: 0;
            }
            &:hover {
                cursor: pointer;
                .svg-icon-sort {
                    opacity: 1;
                }
            }
        }
    }
    @media screen and (max-width: 1199px) {
        td {
            white-space: nowrap;
        }
    }
    .table.table-head-custom thead th.sortable-active {
        color: #ff124c !important;
    }
}

.cursor-default {
    cursor: default !important;
}

.progress-modal {
    height: 3px;
    width: 100%;
}

.alert-icon {
    font-size: 2.5rem;
}

.middle-line {
    text-align: center;
    color: #b5b5c3;
    font-weight: bold;
}

.w-custom {
    width: calc(50% - 10px) !important;
}

.w-20 {
    width: 20px !important;
}

.from-left {
    border-radius: 0.42rem 0rem 0rem 0.42rem;
    border-right: none;
}

.from-right {
    border-radius: 0rem 0.42rem 0.42rem 0rem;
    border-left: none;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type="number"] {
    -moz-appearance: textfield;
}

.overflow-hidden {
    overflow: hidden;
}

.w-100 {
    width: 100%;
}

.ng-select{
    z-index: 1 !important;
}

.ng-select.auto-zindex {
    z-index: auto !important;
}

@media (max-width: 1199.98px) {
    .header-mobile .burger-icon {
        span {
            background-color: #000;
            &:before,
            &:after {
                background-color: #000;
            }
        }
        // Hover State
        &:hover {
            span {
                background-color: #000;
                &::before,
                &::after {
                    background-color: #000;
                }
            }
        }
        // Active State
        &-active {
            span {
                background-color: #000;
                &::before,
                &::after {
                    background-color: #000;
                }
            }
        }
    }
}

.divide-x > * + * {
    border-left: 1px solid;
}

.divide-y > * + * {
    border-bottom: 1px solid;
}

.btn-soft-background {
    background-color: rgba(29, 29, 27, 0.1) !important;

    &:hover {
        background-color: rgba(29, 29, 27, 0.15) !important;
    }
}

.lh-1\.4 {
    line-height: 1.4;
}

.mr-small {
    margin-right: 3.5px !important;
}

.md-scale-1-05 {
    @media (min-width:768px) {
        transform: scale(1.05);
    }
  }