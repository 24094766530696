//
// SVG Icon
//
@import "./variables.main";

.svg-icon {
  @include svg-icon-size(get($svg-icon-sizes, md));
  @include svg-icon-color($text-muted);

  // Theme colors
  @each $name, $color in $theme-text-colors {
    &.svg-icon-#{$name} {
      @include svg-icon-color($color, true);
    }
    &.svg-logo-#{$name} {
      @include svg-logo-color($color, true);
    }
  }

  // Responsive icon sizes
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      // Sizes
      @each $name, $value in $svg-icon-sizes {
        &.svg-icon#{$infix}-#{$name} {
          @include svg-icon-size($value, true);
        }
      }
    }
  }

  &.svg-icon-flip {
    svg {
      transform: scaleX(-1);
    }
  }
}

.color-life {
  svg path {
    fill: $color-life !important;
  }
}

.color-household {
  svg path {
    fill: $color-household !important;
  }
}

.color-auto {
  svg path {
    fill: $color-auto !important;
  }
}

.color-health {
  svg path {
    fill: $color-health !important;
  }
}
  
  .color-auto{
    svg path {
      fill: $color-auto !important;
    }
  }

  .color-employee{
    svg path {
      fill: $color-employee !important;
    }
  }
